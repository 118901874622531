<template>
    <div class="wrapper -contacto">


        <div class="encabezado">
            <div class="container">
                <h2>Contacta con nosotros</h2>
            </div>
        </div>


        <div class="contacto-body container row-center ais">

            <div class="contacto-info">

                <div class="block">
                    <h2>¿Necesitas ayuda?</h2>
                    <p>Contacta con nosotros enviando un e-mail con tu mensaje al siguiente correo: <a href="mailto:info@vdo.es">info@vdo.es</a> o bien
                        llámanos al teléfono <a href="tel:916572121">91 657 2121</a>.</p>
                    <p><strong>¡Estaremos encantados de atenderte!</strong></p>
                </div>
                <div class="block">
                    <h2>Contacto de prensa</h2>
                    <h3>Silvia Cano Villalba</h3>
                    <h4>Responsable de Comunicación y Prensa</h4>

                    <p>C/ Sepúlveda, 11</p>
                    <p>28108 Alcobendas</p>
                    <p>Madrid - España</p>
                    
                    <p><a href="mailto:silvia.cano@continental-corporation.com">silvia.cano@continental-corporation.com</a></p>
                </div>

            </div>

            <div class="rrss">
                <img src="/img/imagen-contacto.jpg" alt="">
                <div class="texto">
                    <h2>También puedes encontrarnos en nuestras redes sociales</h2>

                    <h3>Aftermarket - Recambios</h3>
                    <nav class="rrss-nav row-start">
                        <a target="_blank" href="https://www.facebook.com/continentalautomotivespain/"><i
                                class="fa fa-facebook"></i></a>
                        <a target="_blank" href="https://twitter.com/PrensaVDO"><i class="fa-brands fa-x-twitter"></i></a>
                        <a target="_blank" href="https://www.youtube.com/user/ContiVDO"><i
                                class="fa fa-youtube"></i></a>
                        <a target="_blank" href="https://www.linkedin.com/company/vdo-espana/"><i
                                class="fa fa-linkedin"></i></a>
                    </nav>
                    <h3>Aftermarket - Frenos</h3>
                    <nav class="rrss-nav row-start">
                        <a target="_blank" href="https://www.facebook.com/frenosate"><i class="fa fa-facebook"></i></a>
                        <a target="_blank" href="https://twitter.com/frenosate?lang=es"><i class="fa-brands fa-x-twitter"></i></a>
                        <a target="_blank" href="https://www.youtube.com/channel/UCBw-9_UvZHY08EYlugAY-Pg"><i
                                class="fa fa-youtube"></i></a>
                    </nav>
                </div>
            </div>


        </div>

    </div>
</template>


<script>

    import emailjs from 'emailjs-com';

    export default {
        name: 'contacto',
        data: () => ({
            name: '',
            tipoconsulta: '',
            asunto: '',
            consulta: '',
            email: '',
            politica: false,
            loading: false,
            ok: false
        }),
        methods: {
            validate(e) {

                this.$refs.form.validate().then(success => {

                    if (success) {

                        this.loading = true;

                        emailjs.sendForm('service_h34neee', 'template_1sy2jr4', e.target,
                            'user_mqPLwilzJofcSAqZwAKS0', {
                            name: this.name,
                            from_name: this.name,
                            email: this.email,
                            message: this.consulta
                        }).then((result) => {
                            if (result.status == 200) {
                                this.ok = true;
                                this.loading = false;
                                this.name = '';
                                this.email = '';
                                this.politica = false;
                                this.consulta = '';
                                this.asunto = '';
                            }
                        }, (error) => {
                            this.loading = false;
                            console.log(error);
                        });
                    }
                });


            }
        }
    }

</script>